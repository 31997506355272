import { Layer, Marker, Source } from 'react-map-gl';
import { BonusZone as BonusZoneModel, System } from '@cooltra/api';

import {
  getBonusZoneBordersSourceId,
  getBonusZoneFillLayerId,
  getBonusZoneSourceId,
} from '~/common/Map/BonusZone/get-bonus-zone-layer-id';
import { Circle } from '~/common';

export type BonusZoneProps = {
  bonusZone: BonusZoneModel;
  system: System;
};

export const BonusZone = ({ bonusZone, system }: BonusZoneProps) => {
  const bonusZoneCoordinates = bonusZone.polygon.coordinates[0];
  let northernPoint: null | { latitude: number; longitude: number } = null;

  for (const [longitude, latitude] of bonusZoneCoordinates) {
    if (!northernPoint || northernPoint.latitude < latitude) {
      northernPoint = {
        longitude,
        latitude,
      };
    }
  }

  const isAValidCoordinate = (coordinate: any) =>
    typeof coordinate === 'number';

  const bonusZoneSourceId = getBonusZoneSourceId(bonusZone.bonusZoneId, system);
  const bonusZoneFillLayerId = getBonusZoneFillLayerId(bonusZone.bonusZoneId);

  const bonusZoneBordersSourceId = getBonusZoneBordersSourceId(
    bonusZone.bonusZoneId
  );

  return (
    <>
      {northernPoint &&
        isAValidCoordinate(northernPoint.latitude) &&
        isAValidCoordinate(northernPoint.longitude) && (
          <Marker
            latitude={northernPoint.latitude}
            longitude={northernPoint.longitude}
          >
            <Circle className="w-6 h-6 shrink-0 bg-neutral-400 text-neutral-0 text-base">
              <span className="relative -left-0.5">-€</span>
            </Circle>
          </Marker>
        )}
      <Source
        id={bonusZoneSourceId}
        type="geojson"
        data={{
          type: 'FeatureCollection',
          features: [
            {
              type: 'Feature',
              properties: {},
              geometry: bonusZone.polygon as any,
            },
          ],
        }}
      >
        <Layer
          id={bonusZoneFillLayerId}
          source={bonusZoneSourceId}
          type="fill"
          paint={{
            'fill-translate': [0, 0],
            'fill-outline-color': '#333',
            'fill-color': '#72879D',
            'fill-opacity': [
              'interpolate',
              ['linear'],
              ['zoom'],
              7,
              0,
              12,
              0.1,
              22,
              0.3,
            ],
          }}
        />
      </Source>
      <Source
        id={bonusZoneBordersSourceId}
        type="geojson"
        data={{
          type: 'Feature',
          properties: {},
          geometry: {
            type: 'LineString',
            coordinates: bonusZoneCoordinates,
          },
        }}
      >
        <Layer
          source={bonusZoneBordersSourceId}
          type="line"
          paint={{
            'line-color': '#72879D',
            'line-width': 2,
          }}
        />
      </Source>
    </>
  );
};
