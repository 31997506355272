import {
  useVehicleStatusQuery,
  useVehicleTasksQuery,
  Vehicle,
} from '@cooltra/api';
import { MdClose } from 'react-icons/md';
import { RouterBaseLink, RouterCardLink } from '@cooltra/navigation';
import { useIntl } from 'react-intl';

import {
  BatteryIcon,
  BatteryIconLabel,
  IconButton,
  ModelImage,
  ModelName,
  OpenTasksCount,
  OperationalConditionBadge,
  OperationalStateBadge,
} from '~/common';
import { useLocationName } from '~/hooks';
import { useSelectVehicle } from '~/libs/select-vehicle';

import messages from './messages';

export type MapCardProps = Pick<
  Vehicle,
  | 'vehicleId'
  | 'externalId'
  | 'licensePlate'
  | 'model'
  | 'locationId'
  | 'operationalConditions'
  | 'operationalState'
  | 'identificationNumber'
>;

export const MapCard = ({
  vehicleId,
  externalId,
  licensePlate,
  model,
  identificationNumber,
  locationId,
  operationalConditions = [],
  operationalState,
}: MapCardProps) => {
  const { formatMessage } = useIntl();
  const { data: status } = useVehicleStatusQuery(vehicleId, {
    retry: false,
  });

  const locationName = useLocationName(locationId);
  const { deselectVehicle } = useSelectVehicle();

  const { data: tasks = [] } = useVehicleTasksQuery(
    vehicleId || '',
    { open: 'true' },
    { enabled: !!vehicleId }
  );

  const blockingTasks = tasks.filter(({ blocking }) => blocking);

  return (
    <RouterCardLink isMultiAction to={`vehicles/${vehicleId}`}>
      <div className="flex items-center py-4 pl-2 pr-4">
        <ModelImage
          className="h-12 mr-2"
          model={model}
          identificationNumber={identificationNumber}
        />
        <div>
          <span className="block text-base font-semibold text-neutral-1000 mb-1">
            {externalId}
          </span>
          <div className="flex items-center gap-2">
            <span className="text-xs text-neutral-800">{licensePlate}</span>
            <span className="text-neutral-100 text-xs">|</span>
            <span className="text-xs text-neutral-800">
              <ModelName model={model} />
            </span>
          </div>
        </div>
      </div>
      <div className="flex items-center justify-between px-4 pb-4 gap-2 border-b border-neutral-50">
        <div className="flex flex-wrap items-center gap-2">
          <OperationalStateBadge
            operationalState={operationalState}
            className="text-xs"
          />
          {operationalConditions
            .filter(
              (operationalCondition) => operationalCondition != 'OPERATIONAL'
            )
            .map((operationalCondition) => (
              <OperationalConditionBadge
                key={operationalCondition}
                className="text-xs"
                operationalCondition={operationalCondition}
              />
            ))}
        </div>
      </div>
      <div className="flex items-center justify-between px-4 py-3 gap-2 min-h-11">
        <div className="text-xs text-neutral-500 py-0.5">
          <RouterBaseLink
            to={`vehicles/${vehicleId}/location`}
            className="no-underline"
          >
            <span>{locationName}</span>
          </RouterBaseLink>
          {locationName && !!tasks.length && <span>{`, `}</span>}
          {!!tasks.length && (
            <RouterBaseLink
              to={`vehicles/${vehicleId}/tasks`}
              className="no-underline"
            >
              <span>
                <OpenTasksCount
                  totalTasks={tasks.length}
                  totalBlockingTasks={blockingTasks.length}
                />
              </span>
            </RouterBaseLink>
          )}
        </div>
        {status?.batteryCharge && (
          <div className="flex items-center">
            <BatteryIconLabel
              charge={status.batteryCharge.value}
              className="mr-1.5 mt-px text-xs"
            />
            <div className="w-6 h-3">
              <BatteryIcon charge={status.batteryCharge.value} />
            </div>
          </div>
        )}
      </div>
      <IconButton
        aria-label={formatMessage(messages.close)}
        className="absolute p-2 top-0 right-0"
        onClick={deselectVehicle}
      >
        <MdClose />
      </IconButton>
    </RouterCardLink>
  );
};
