import { useVehicleStatusQuery, Vehicle } from '@cooltra/api';
import { useAuthClaimsQuery } from '@cooltra/auth-api';
import { getErrorStatus } from '@cooltra/axios';
import { Placeholder, TopLoadingBar } from '@cooltra/ui';
import { Outlet, useMatch } from 'react-router-dom';
import { classNames } from '@cooltra/utils';

import {
  Error,
  ModelImage,
  ModelName,
  Screen,
  TopNavigation,
  FloatingMenu,
  OperationalStateBadge,
  OperationalConditionBadge,
  GoogleMapsLink,
} from '~/common';
import { useSelectVehicle } from '~/libs/select-vehicle';

import { LocationListItem } from './LocationListItem/LocationListItem';
import { TelematicsListItem } from './TelematicsListItem/TelematicsListItem';
import { BatteryListItem } from './BatteryListItem/BatteryListItem';
import { OpenTasksListItem } from './OpenTasksListItem/OpenTasksListItem';
import { TakenByListItem } from './TakenByListItem/TakenByListItem';
import { MiniMap } from './MiniMap';
import { RentalsListItem } from './RentalsListItem/RentalsListItem';

export type VehicleInfoProps = {
  onClose: () => void;
  vehicle: Vehicle;
};

export const VehicleInfo = ({
  onClose,
  vehicle: {
    vehicleId,
    externalId,
    model,
    licensePlate,
    operationalState,
    identificationNumber,
    locationId,
    operationalConditions,
    takenBy,
    system,
  },
}: VehicleInfoProps) => {
  const {
    data: status,
    error,
    isLoading,
  } = useVehicleStatusQuery(vehicleId, {
    retry: false,
  });

  const { selectVehicle } = useSelectVehicle();

  const { hasPermission } = useAuthClaimsQuery();

  const selectAndClose = () => {
    onClose();
    selectVehicle(vehicleId);
  };

  const match = useMatch({
    path: `/vehicles/${vehicleId}`,
    end: true,
  });

  if (error && getErrorStatus(error) !== 404) {
    return (
      <Screen
        className={classNames(!match && 'hidden')}
        header={
          <TopNavigation.Bar
            renderRight={<TopNavigation.Close onClick={onClose} />}
          />
        }
        content={<Error />}
      />
    );
  }

  return (
    <>
      <Screen
        className={classNames(!match && 'hidden')}
        data-testid="VEHICLE_INFO_SCREEN"
        header={
          <div className="border-b border-neutral-100 pb-4">
            {isLoading && <TopLoadingBar />}
            <TopNavigation.Bar
              renderRight={<TopNavigation.Close onClick={onClose} />}
              title={<TopNavigation.Title>{externalId}</TopNavigation.Title>}
            />
            <div className="flex flex-col items-center">
              <ModelImage
                className="h-10 mb-4"
                identificationNumber={identificationNumber}
                model={model}
              />
              <div className="flex items-center gap-2 mb-2">
                <span className="text-xs text-neutral-800">{licensePlate}</span>
                <span className="text-neutral-100 text-xs">|</span>
                <span className="text-xs text-neutral-800">
                  <ModelName model={model} />
                </span>
              </div>
              <div className="flex flex-wrap justify-center items-center gap-2 my-2 mx-4">
                <OperationalStateBadge
                  operationalState={operationalState}
                  className="text-xs"
                />
                {operationalConditions
                  .filter(
                    (operationalCondition) =>
                      operationalCondition != 'OPERATIONAL'
                  )
                  .map((operationalCondition) => (
                    <OperationalConditionBadge
                      key={operationalCondition}
                      className="text-xs"
                      operationalCondition={operationalCondition}
                    />
                  ))}
              </div>
              {status && (
                <GoogleMapsLink
                  system={system}
                  geoLocation={status.geoLocation}
                  className="max-w-xs px-10 h-10"
                />
              )}
            </div>
          </div>
        }
        content={
          <div>
            {isLoading ? (
              <Placeholder className="h-20 rounded-none" />
            ) : status ? (
              <MiniMap
                onClick={selectAndClose}
                vehicleStatus={status}
                operationalState={operationalState}
                error={error}
              />
            ) : null}
            <ul className="pb-20">
              <BatteryListItem vehicleId={vehicleId} />
              <TelematicsListItem vehicleId={vehicleId} />
              {takenBy ? (
                <TakenByListItem takenBy={takenBy} />
              ) : (
                <LocationListItem locationId={locationId} />
              )}
              {hasPermission('read:tasks') ? (
                <OpenTasksListItem vehicleId={vehicleId} />
              ) : (
                <></>
              )}
              {hasPermission('read:rentals') ? (
                <RentalsListItem vehicleId={vehicleId} />
              ) : (
                <></>
              )}
            </ul>
            <FloatingMenu
              vehicleId={vehicleId}
              onCloseWorkUnit={onClose}
              onStartWorkUnit={selectAndClose}
            />
          </div>
        }
      />
      <Outlet />
    </>
  );
};
