import { useVehicleStatusQuery } from '@cooltra/api';
import { getErrorStatus } from '@cooltra/axios';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';
import { useNavigate } from 'react-router-dom';

import {
  TopNavigation,
  Screen,
  BatteryIconLabel,
  BatteryIcon,
  ListItem,
  NotAvailable,
  Error,
  FloatingMenu,
  DateTime,
  TimeAgo,
} from '~/common';

import messages from './messages';
import { NoDataAvailable } from './NoDataAvailable/NoDataAvailable';

export type BatteryProps = {
  onClose: () => void;
  vehicleId: string;
};

export const Battery = ({ onClose, vehicleId }: BatteryProps) => {
  const navigate = useNavigate();

  const onBack = () => navigate(`/vehicles/${vehicleId}`);

  const {
    data: status,
    error,
    isError,
  } = useVehicleStatusQuery(vehicleId, {
    retry: false,
  });

  const renderContent = useCallback(() => {
    if (getErrorStatus(error) === 404) {
      return <NoDataAvailable />;
    }

    if (isError) {
      return <Error />;
    }

    if (!status) {
      return <></>;
    }

    const { voltage, boardVoltage, range } = status;

    return (
      <>
        <div
          data-testid="BATTERY_AVG"
          className="flex flex-col items-center px-4 pt-1 pb-6 border-b border-neutral-100"
        >
          <div className="w-20 h-9 mb-3">
            <BatteryIcon charge={status.batteryCharge?.value || 0} />
          </div>
          {status.batteryCharge ? (
            <>
              <BatteryIconLabel
                charge={status.batteryCharge.value}
                className="text-base"
              />
              <span className="text-xs text-neutral-700 mt-3">
                <FormattedMessage {...messages.updated} />
                <span> </span>
                <TimeAgo ISODate={status.batteryCharge.updatedAt} />
              </span>
            </>
          ) : (
            <NotAvailable />
          )}
        </div>
        <ul>
          <li>
            <ListItem data-testid="BATTERY_A">
              <div className="flex justify-between items-center gap-4">
                <span className="text-base font-semibold text-neutral-700">
                  <FormattedMessage {...messages.batteryA} />
                </span>
                {status.battery1Charge ? (
                  <div className="flex items-center">
                    <span className="text-sm text-neutral-500">
                      {`${status.battery1Charge.value}${status.battery1Charge.unit}`}
                    </span>
                    <div className="ml-1 w-6 h-3">
                      <BatteryIcon charge={status.battery1Charge.value || 0} />
                    </div>
                  </div>
                ) : (
                  <NotAvailable />
                )}
              </div>
            </ListItem>
          </li>
          <li>
            <ListItem data-testid="BATTERY_B">
              <div className="flex justify-between items-center gap-4">
                <span className="text-base font-semibold text-neutral-700">
                  <FormattedMessage {...messages.batteryB} />
                </span>
                {status.battery2Charge ? (
                  <div className="flex items-center">
                    <span className="text-sm text-neutral-500">
                      {`${status.battery2Charge.value}${status.battery2Charge.unit}`}
                    </span>
                    <div className="ml-1 w-6 h-3">
                      <BatteryIcon charge={status.battery2Charge.value || 0} />
                    </div>
                  </div>
                ) : (
                  <NotAvailable />
                )}
              </div>
            </ListItem>
          </li>
          <li>
            <ListItem data-testid="VOLTAGE">
              <div className="flex justify-between items-center gap-4">
                <span className="text-base font-semibold text-neutral-700">
                  <FormattedMessage {...messages.voltage} />
                </span>
                {voltage ? (
                  <span className="text-sm text-neutral-500">
                    {`${voltage.value} ${voltage.unit}`}
                  </span>
                ) : (
                  <NotAvailable />
                )}
              </div>
            </ListItem>
          </li>
          <li>
            <ListItem data-testid="BOARD_VOLTAGE">
              <div className="flex justify-between items-center gap-4">
                <span className="text-base font-semibold text-neutral-700">
                  <FormattedMessage {...messages.boardVoltage} />
                </span>
                {boardVoltage ? (
                  <span className="text-sm text-neutral-500">
                    {`${boardVoltage.value} ${boardVoltage.unit}`}
                  </span>
                ) : (
                  <NotAvailable />
                )}
              </div>
            </ListItem>
          </li>
          <li>
            <ListItem data-testid="RANGE">
              <div className="flex justify-between items-center gap-4">
                <span className="text-base font-semibold text-neutral-700">
                  <FormattedMessage {...messages.range} />
                </span>
                {range ? (
                  <span className="text-sm text-neutral-500">
                    {`${range.value} ${range.unit}`}
                  </span>
                ) : (
                  <NotAvailable />
                )}
              </div>
            </ListItem>
          </li>
          <li>
            <ListItem data-testid="LAST_SWAP">
              <div className="flex justify-between items-center gap-4">
                <span className="text-base font-semibold text-neutral-700">
                  <FormattedMessage {...messages.lastSwap} />
                </span>
                {status.batteriesSwappedAt ? (
                  <span className="text-sm text-neutral-500">
                    <DateTime date={status.batteriesSwappedAt} />
                  </span>
                ) : (
                  <NotAvailable />
                )}
              </div>
            </ListItem>
          </li>
        </ul>
        <FloatingMenu
          vehicleId={vehicleId}
          onCloseWorkUnit={onClose}
          onStartWorkUnit={onClose}
        />
      </>
    );
  }, [error, isError, onClose, status, vehicleId]);

  return (
    <Screen
      data-testid="VEHICLE_BATTERY_SCREEN"
      header={
        <TopNavigation.Bar
          renderLeft={<TopNavigation.Back onClick={onBack} />}
          renderRight={<TopNavigation.Close onClick={onClose} />}
          title={
            <TopNavigation.Title>
              <FormattedMessage {...messages.title} />
            </TopNavigation.Title>
          }
        />
      }
      content={renderContent()}
    />
  );
};
