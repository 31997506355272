import { MultiPolygon } from '@cooltra/api';
import { Layer, LayerProps, Source } from 'react-map-gl';

export const geofenceLayer: LayerProps = {
  id: 'geofence-area',
  type: 'fill',
  source: 'geofence',
  paint: {
    'fill-color': '#111',
    'fill-opacity': [
      'interpolate',
      ['linear'],
      ['zoom'],
      7,
      0,
      12,
      0.3,
      22,
      0.5,
    ],
  },
};

type GeofencesMapSource = {
  id: string;
  layers: JSX.Element[];
  data: any[];
};

export const getGeofencesMapSource = (
  geofence: MultiPolygon
): GeofencesMapSource => ({
  id: 'geofences',
  data: [
    {
      type: 'Feature',
      properties: {},
      geometry: geofence,
    },
  ],
  layers: [<Layer key="geofenceLayer" {...geofenceLayer} />],
});

type GeofencesProps = {
  geofences: MultiPolygon;
};

export const Geofences = ({ geofences }: GeofencesProps) => {
  const source = getGeofencesMapSource(geofences);

  return (
    <Source
      key={source.id}
      id={source.id}
      type="geojson"
      buffer={10}
      data={{
        type: 'FeatureCollection',
        features: source.data,
      }}
    >
      {source.layers}
    </Source>
  );
};
