import { useMap } from 'react-map-gl';
import { MapLayerMouseEvent } from 'mapbox-gl';
import { useCallback, useEffect } from 'react';
import { useGeofencesQuery } from '@cooltra/api';

import { useMapCenter } from '~/libs/map-center';
import { useSelectVehicle } from '~/libs/select-vehicle';
import { useCurrentPosition } from '~/libs/current-position';
import { useTrackUser } from '~/libs/track-user';
import { isTestingEnv } from '~/utils/e2e';
import { useUserSystems } from '~/hooks';

import { BonusZoneByHomeSystem } from '../BonusZone';
import { BaseMap, BaseMapProps } from '../BaseMap/BaseMap';
import { Directions } from '../Directions/Directions';
import { CurrentPosition } from '../CurrentPosition/CurrentPosition';
import { Geofences } from '../Geofences/Geofences';

import { VehicleFeature, VehicleMapSource } from './types';
import { Sources } from './Sources';

export type VehiclesMapProps = {
  vehiclesMapSources: VehicleMapSource[];
  vehicleFeatures: VehicleFeature[];
} & Pick<BaseMapProps, 'interactiveLayerIds' | 'children'>;

export const VehiclesMap = ({
  interactiveLayerIds,
  vehiclesMapSources,
  vehicleFeatures,
  children,
}: VehiclesMapProps) => {
  const map = useMap().home;
  const { isTrackingUser, stopTrackingUser } = useTrackUser();

  const { selectVehicle } = useSelectVehicle();
  const { viewState, updateViewState } = useMapCenter();
  const currentPosition = useCurrentPosition();
  const systems = useUserSystems();
  const { data: geofences } = useGeofencesQuery();

  useEffect(() => {
    if (isTrackingUser && currentPosition.length) {
      updateViewState(({ zoom }) => ({
        zoom,
        longitude: currentPosition[0],
        latitude: currentPosition[1],
      }));
    }
  }, [currentPosition, isTrackingUser, updateViewState]);

  const onLayerClick = useCallback(
    (e: MapLayerMouseEvent) => {
      const features = e.features || [];
      stopTrackingUser();
      features.forEach(({ geometry, properties }) => {
        const [longitude, latitude] = (geometry as any).coordinates;
        if (properties) {
          selectVehicle(properties.vehicleId);
        } else if (map) {
          const currentZoom = map.getZoom();
          map.flyTo({
            zoom: currentZoom < 16 ? 16 : currentZoom,
            center: {
              lat: latitude,
              lon: longitude,
            },
          });
        }
      });
    },
    [map, selectVehicle, stopTrackingUser]
  );

  const onLoad = useCallback(() => {
    if (currentPosition.length) {
      updateViewState(({ zoom }) => ({
        longitude: currentPosition[0],
        latitude: currentPosition[1],
        zoom,
      }));
    }
  }, [currentPosition, updateViewState]);

  if (isTestingEnv()) {
    return <div className="h-full w-full bg-neutral-50">{children}</div>;
  }

  if (!geofences) {
    return null;
  }

  return (
    <BaseMap
      id="home"
      interactiveLayerIds={interactiveLayerIds}
      style={{ width: '100%', height: '100%' }}
      onClick={onLayerClick}
      onMove={stopTrackingUser}
      onLoad={onLoad}
      {...(isTrackingUser ? viewState : {})}
    >
      <Geofences geofences={geofences} />
      <CurrentPosition />
      <Directions />
      <Sources
        vehicleFeatures={vehicleFeatures}
        vehiclesMapSources={vehiclesMapSources}
      />
      {systems.map((system) => (
        <BonusZoneByHomeSystem system={system} key={`bonus-zone-${system}`} />
      ))}
      {children}
    </BaseMap>
  );
};
