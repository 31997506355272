import { MultiPolygon, System, useGeofenceQuery } from '@cooltra/api';
import { Layer, LayerProps, Source } from 'react-map-gl';
import { hoursToMilliseconds } from 'date-fns';

export const geofenceLayer: LayerProps = {
  id: 'geofence-area',
  type: 'fill',
  source: 'geofence',
  paint: {
    'fill-color': '#111',
    'fill-opacity': [
      'interpolate',
      ['linear'],
      ['zoom'],
      7,
      0,
      12,
      0.3,
      22,
      0.5,
    ],
  },
};

type GeofencesMapSource = {
  id: string;
  layers: JSX.Element[];
  data: GeoJSON.Feature<any>;
};

export const getGeofencesMapSources = (
  geofences: MultiPolygon[]
): GeofencesMapSource[] =>
  geofences.map((geofence) => ({
    id: 'geofence',
    data: {
      type: 'Feature',
      properties: {},
      geometry: geofence,
    },
    layers: [<Layer key="geofenceLayer" {...geofenceLayer} />],
  }));

export const Geofence = ({ system }: { system: System }) => {
  const { data: geofence } = useGeofenceQuery(system, {
    staleTime: hoursToMilliseconds(8),
  });

  const finalGeofence = geofence?.final;

  if (!finalGeofence) {
    return <></>;
  }

  return (
    <>
      {getGeofencesMapSources([finalGeofence]).map((source) => (
        <Source
          key={source.id}
          id={source.id}
          type="geojson"
          data={source.data}
        >
          {source.layers}
        </Source>
      ))}
    </>
  );
};
