import { FormattedMessage, useIntl /*, useIntl*/ } from 'react-intl';
import { Button } from '@cooltra/ui';
import { classNames } from '@cooltra/utils';
import { MdTwoWheeler } from 'react-icons/md';

import { ActionButtonProps } from '../types';

import messages from './messages';

type TakeActionButtonProps = ActionButtonProps & {
  isTakeMenuOpen: boolean;
  className: string;
  disabled: boolean;
};

export const TakeMenu = ({
  onSettled,
  isTakeMenuOpen,
  className,
  disabled = false,
}: TakeActionButtonProps) => {
  const { formatMessage } = useIntl();
  return (
    <>
      {isTakeMenuOpen ? (
        <div className="flex flex-col items-center justify-center">
          <Button
            className={classNames(
              'rounded-full',
              'btn-size-lg',
              'p-0',
              'w-16',
              'h-16',
              className
            )}
            emphasis="high"
            onClick={onSettled}
            disabled={disabled}
            aria-label={formatMessage(messages.takeVehicle)}
          >
            <MdTwoWheeler className="w-7 h-7" />
          </Button>

          <span
            className={classNames(
              'text-sm',
              'truncate',
              'text-neutral-0',
              'mt-1',
              className
            )}
          >
            <FormattedMessage {...messages.takeVehicle} />
          </span>
        </div>
      ) : (
        <div className="flex flex-col items-center justify-center">
          <Button
            className={classNames(
              'rounded-full',
              'btn-size-lg',
              'p-0',
              'w-16',
              'h-16',
              className
            )}
            onClick={onSettled}
            disabled={disabled}
            aria-label={formatMessage(messages.takeVehicle)}
          >
            <MdTwoWheeler className="w-7 h-7" />
          </Button>
          {!disabled && (
            <span className="text-sm truncate text-neutral-0 mt-1">
              <FormattedMessage {...messages.takeVehicle} />
            </span>
          )}
        </div>
      )}
    </>
  );
};
