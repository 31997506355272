import {
  System,
  useVehicleQuery as useApiVehicleQuery,
  Vehicle,
  VehicleListItem,
} from '@cooltra/api';
import { AxiosError } from 'axios';
import {
  QueryClient,
  useQueryClient,
  UseQueryOptions,
} from '@tanstack/react-query';
import { useEffect } from 'react';

import {
  ExtendedVehicleListItem,
  Preset,
  useVehiclesFilters,
} from '~/libs/vehicles-filters';

import { useUserSystems } from './useUserSystems';

const hasOperationalConditionChanged = (
  newVehicle: Vehicle,
  oldVehicle: VehicleListItem | undefined
): boolean => {
  if (!oldVehicle) {
    return true;
  }

  return (
    newVehicle.operationalState != oldVehicle.operationalState ||
    JSON.stringify(newVehicle.operationalConditions) !=
      JSON.stringify(oldVehicle.operationalConditions)
  );
};

const updateFilteredVehicles = (
  queryClient: QueryClient,
  systems: System[],
  vehicle: Vehicle
) => {
  const oldVehicles = queryClient.getQueryData<VehicleListItem[]>([
    'vehicles',
    { system: systems },
  ]);

  const oldVehicle = oldVehicles?.find(
    (vehicleListItem) => vehicleListItem.vehicleId == vehicle.vehicleId
  );

  if (oldVehicles && hasOperationalConditionChanged(vehicle, oldVehicle)) {
    const newVehicles = oldVehicles.map((vehicleListItem) => {
      if (vehicleListItem.vehicleId == vehicle.vehicleId) {
        return {
          ...vehicle,
          openTasks: vehicleListItem.openTasks,
          status: vehicleListItem.status,
        };
      }

      return vehicleListItem;
    });

    queryClient.setQueryData<VehicleListItem[]>(
      ['vehicles', { system: systems }],
      newVehicles
    );
  }
};

const updatePresetFilteredVehicles = (
  queryClient: QueryClient,
  preset: Preset | undefined,
  vehicle: Vehicle
) => {
  const oldVehicles = queryClient.getQueryData<ExtendedVehicleListItem[]>([
    'preset-vehicles',
    preset,
  ]);

  const oldVehicle = oldVehicles?.find(
    (vehicleListItem) => vehicleListItem.vehicleId == vehicle.vehicleId
  );

  if (oldVehicles && hasOperationalConditionChanged(vehicle, oldVehicle)) {
    const newVehicles = oldVehicles.map((vehicleListItem) => {
      if (vehicleListItem.vehicleId == vehicle.vehicleId) {
        const updatedVehicle: ExtendedVehicleListItem = {
          ...vehicle,
          openTasks: vehicleListItem.openTasks,
          status: vehicleListItem.status,
          isMatch: vehicleListItem.isMatch,
        };
        return updatedVehicle;
      } else {
        return vehicleListItem;
      }
    });

    queryClient.setQueryData<ExtendedVehicleListItem[]>(
      ['preset-vehicles', preset],
      newVehicles
    );
  }
};

export const useVehicleQuery = (
  vehicleId: string,
  options: Omit<
    UseQueryOptions<Vehicle, AxiosError, Vehicle, string[]>,
    'queryKey'
  > = {}
) => {
  const systems = useUserSystems();
  const { values } = useVehiclesFilters();
  const queryClient = useQueryClient();

  const vehicleQuery = useApiVehicleQuery(vehicleId, {
    initialData: () => {
      const vehicles = queryClient.getQueryData<VehicleListItem[]>([
        'vehicles',
        { system: systems },
      ]);

      if (!vehicles) {
        return undefined;
      }

      const vehicleListItem = vehicles.find(
        (vehicle) => vehicle.vehicleId === vehicleId
      );

      if (!vehicleListItem) {
        return undefined;
      }

      return {
        ...vehicleListItem,
        createdAt: '',
      };
    },
    ...options,
  });

  useEffect(() => {
    const vehicle = vehicleQuery.data;
    if (vehicle) {
      updateFilteredVehicles(queryClient, systems, vehicle);
      updatePresetFilteredVehicles(queryClient, values.preset, vehicle);
    }
  }, [queryClient, systems, values.preset, vehicleQuery.data]);

  return vehicleQuery;
};
