import { PropsWithChildren } from 'react';
import { FormProvider } from '@cooltra/form';

import {
  VehiclesFiltersFormValues,
  vehiclesFiltersInitialValues,
} from './vehicles-filters-form';
import {
  VehiclesFilterFormPersistor,
  localStorageKey,
} from './VehiclesFilterFormPersistor';

export const VehiclesFiltersForm = ({ children }: PropsWithChildren) => {
  const persistedState = localStorage.getItem(localStorageKey);

  const initialValues: VehiclesFiltersFormValues = persistedState
    ? {
        ...vehiclesFiltersInitialValues,
        ...JSON.parse(persistedState),
      }
    : {
        ...vehiclesFiltersInitialValues,
        operationalCondition: ['!RENTED', '!RESERVED'],
      };

  return (
    <FormProvider initialValues={initialValues}>
      <VehiclesFilterFormPersistor />
      {children}
    </FormProvider>
  );
};
