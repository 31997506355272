import {
  useStartWorkUnitMutation,
  useWorkUnitQuery,
  useVehicleStatusQuery,
  Vehicle,
} from '@cooltra/api';
import { captureException } from '@sentry/react';
import { useToggle } from '@cooltra/hooks';
import { MdCheck } from 'react-icons/md';
import { Button, Icon, Modal, Textarea } from '@cooltra/ui';
import { AxiosError } from 'axios';
import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { QueryObserverResult } from '@tanstack/react-query';

import { useAutomaticHonk } from '~/libs/automatic-honk';
import { useNotification } from '~/libs/notifications';
import { useVehiclePolling } from '~/libs/polling-data';
import { useSelectVehicle } from '~/libs/select-vehicle';
import { useCurrentPosition } from '~/libs/current-position';

import { ActionButton } from '../ActionButton/ActionButton';

import { getErrorMessageFromStatusCode } from './errors';
import messages from './messages';
import { useLockAndCloseMutation } from './useLockAndCloseMutation';
import { WorkUnitFutureState } from './WorkUnitFutureState';

export type WorkUnitProps = {
  onCloseWorkUnit?: () => void;
  onStartWorkUnit?: () => void;
  vehicle: Vehicle;
  isStale: boolean;
  refetch: () => Promise<
    QueryObserverResult<Vehicle, AxiosError<unknown, any>>
  >;
};

export const WorkUnit = ({
  vehicle: {
    vehicleId,
    externalId,
    model,
    identificationNumber,
    operationalConditions,
  },
  onCloseWorkUnit,
  onStartWorkUnit,
  isStale,
  refetch,
}: WorkUnitProps) => {
  const { formatMessage } = useIntl();
  const [isCloseWorkUnitModalOpen, closeWorkUnitModalActions] = useToggle();
  const [isWorkUnitConfirmationModalOpen, workUnitConfirmationModalActions] =
    useToggle();
  const [comment, setComment] = useState('');

  const { setHasHonked } = useAutomaticHonk();
  const { deselectVehicle } = useSelectVehicle();
  const currentPosition = useCurrentPosition();

  const { data: workUnit } = useWorkUnitQuery();
  const { data: vehicleStatus } = useVehicleStatusQuery(vehicleId, {
    retry: false,
  });

  const { activatePollingVehicle } = useVehiclePolling();

  const { addErrorNotification, addSuccessNotification } = useNotification();

  const startQuery = useStartWorkUnitMutation();

  const checkRentedOrReserved = async () => {
    if (isStale) {
      const { data: vehicleUpdated } = await refetch();

      if (
        vehicleUpdated?.operationalConditions.includes('RENTED') ||
        vehicleUpdated?.operationalConditions.includes('RESERVED')
      ) {
        workUnitConfirmationModalActions.toggleOn();
        return;
      }
    } else {
      if (
        operationalConditions.includes('RENTED') ||
        operationalConditions.includes('RESERVED')
      ) {
        workUnitConfirmationModalActions.toggleOn();
        return;
      }
    }

    handleStart();
  };

  const handleStart = () =>
    startQuery
      .mutateAsync({
        vehicleId,
        geoposition: currentPosition.length
          ? [currentPosition[1], currentPosition[0]]
          : undefined,
      })
      .then(() => {
        onStartWorkUnit && onStartWorkUnit();
        addSuccessNotification(formatMessage(messages.workStarted));
        activatePollingVehicle(vehicleId);
        setHasHonked(false);
        workUnitConfirmationModalActions.toggleOff();
      })
      .catch((error: AxiosError) => {
        if (error?.response?.status) {
          addErrorNotification(
            formatMessage(getErrorMessageFromStatusCode(error.response.status))
          );
        } else {
          addErrorNotification();
          captureException(error);
        }
      });

  const lockAndCloseQuery = useLockAndCloseMutation(
    vehicleId,
    workUnit ? workUnit.workUnitId : undefined
  );

  const handleClose = () => {
    lockAndCloseQuery
      .mutateAsync({ comment })
      .catch(() => addErrorNotification())
      .finally(() => {
        closeWorkUnitModalActions.toggleOff();
        onCloseWorkUnit && onCloseWorkUnit();
        addSuccessNotification(formatMessage(messages.workFinished));
        activatePollingVehicle(vehicleId);
        deselectVehicle();
        setComment('');
      });
  };

  if (!vehicleStatus) {
    return <></>;
  }

  return (
    <>
      {workUnit ? (
        workUnit.vehicleId === vehicleId ? (
          <Button
            className="rounded-full shadow-xl"
            emphasis="high"
            onClick={closeWorkUnitModalActions.toggleOn}
            leadingIcon={
              <Icon className="text-primary-300 mr-1">
                <MdCheck />
              </Icon>
            }
          >
            <FormattedMessage {...messages.workDone} />
          </Button>
        ) : (
          <></>
        )
      ) : (
        <ActionButton
          className="rounded-full shadow-xl"
          type="success"
          loading={!isWorkUnitConfirmationModalOpen && startQuery.isPending}
          onClick={checkRentedOrReserved}
        >
          <FormattedMessage {...messages.startWork} />
        </ActionButton>
      )}
      <Modal
        isOpen={isCloseWorkUnitModalOpen}
        shouldReturnFocusAfterClose={false}
        hideHeader
        className="mx-10 rounded-3xl"
      >
        <div className="p-6 w-80" data-testid="CLOSE_WORK_UNIT_MODAL">
          <h3 className="text-neutral-1000 font-semibold text-base text-center mb-4">
            <FormattedMessage {...messages.confirm} />
          </h3>
          {workUnit && (
            <WorkUnitFutureState
              externalId={externalId}
              model={model}
              workUnitId={workUnit.workUnitId}
              identificationNumber={identificationNumber}
            />
          )}
          <Textarea
            rows={3}
            className="mb-3"
            disabled={lockAndCloseQuery.isPending}
            placeholder={formatMessage(messages.placeholder)}
            onChange={({ target }) => setComment(target.value)}
            value={comment}
          />
          <div className="flex flex-col gap-2.5">
            <Button
              loading={lockAndCloseQuery.isPending}
              className="rounded-full"
              onClick={handleClose}
              emphasis="high"
            >
              <FormattedMessage {...messages.finishWork} />
            </Button>
            <Button
              className="rounded-full"
              onClick={closeWorkUnitModalActions.toggleOff}
            >
              <FormattedMessage {...messages.cancel} />
            </Button>
          </div>
        </div>
      </Modal>
      <Modal
        isOpen={isWorkUnitConfirmationModalOpen}
        hideHeader
        className="mx-10 rounded-3xl"
      >
        <div className="p-6 w-80" data-testid="CONFIRM_WORK_UNIT_MODAL">
          <h3 className="text-neutral-1000 font-semibold text-base text-center mb-4">
            <FormattedMessage {...messages.confirmWorkStart} />
          </h3>
          <p className="text-sm text-neutral-500 text-center mb-6">
            <FormattedMessage {...messages.confirmRentedOrReserved} />
          </p>
          <div className="flex flex-col gap-2.5">
            <Button
              loading={startQuery.isPending}
              className="rounded-full"
              onClick={handleStart}
              emphasis="high"
            >
              <FormattedMessage {...messages.startWork} />
            </Button>
            <Button
              className="rounded-full"
              onClick={workUnitConfirmationModalActions.toggleOff}
            >
              <FormattedMessage {...messages.cancel} />
            </Button>
          </div>
        </div>
      </Modal>
    </>
  );
};
