import { OperationalCondition, Task, VehicleListItem } from '@cooltra/api';
import { evaluate, Expression } from 'json-expression-eval';

export type VehiclesFiltersPresetContext = Omit<VehicleListItem, 'status'> & {
  batteryCharge: number | undefined;
};

type FunctionTable = {
  batteryRange: (
    [min, max]: [min: number, max: number],
    ctx: VehiclesFiltersPresetContext
  ) => boolean;
  containsOperationalCondition: (
    condition: OperationalCondition,
    ctx: VehiclesFiltersPresetContext
  ) => boolean;
  notContainOperationalCondition: (
    condition: OperationalCondition,
    ctx: VehiclesFiltersPresetContext
  ) => boolean;
  isTaskMatch: (
    task: Pick<Task, 'blocking' | 'operatorGroupId'>,
    ctx: VehiclesFiltersPresetContext
  ) => boolean;
  isTaskTitleMatch: (
    task: Pick<Task, 'title'>,
    ctx: VehiclesFiltersPresetContext
  ) => boolean;
  containsTaskTags: (
    task: Pick<Task, 'tagIds'>,
    ctx: VehiclesFiltersPresetContext
  ) => boolean;
};

export type VehiclesFiltersExpression = Expression<
  VehiclesFiltersPresetContext,
  FunctionTable
>;

function haveCommonElements<T>(array1: T[], array2: T[]): boolean {
  return array1.some((element) => array2.includes(element));
}

const functionsTable: FunctionTable = {
  batteryRange: ([min, max], { batteryCharge }) =>
    batteryCharge === undefined
      ? false
      : batteryCharge >= min && batteryCharge < max,
  containsOperationalCondition: (condition, { operationalConditions }) =>
    operationalConditions.includes(condition),
  notContainOperationalCondition: (condition, { operationalConditions }) =>
    !operationalConditions.includes(condition),
  isTaskMatch: ({ blocking, operatorGroupId }, { openTasks }) =>
    !!openTasks.find(
      (task) =>
        task.operatorGroupId === operatorGroupId && task.blocking === blocking
    ),
  isTaskTitleMatch: ({ title }, { openTasks }) =>
    !!openTasks.find((task) => task.title === title),
  containsTaskTags: ({ tagIds }, { openTasks }) =>
    !!openTasks.find((task) => haveCommonElements(task.tagIds, tagIds)),
};

export const createIsPresetMatch =
  (expression: VehiclesFiltersExpression) =>
  (vehicle: VehiclesFiltersPresetContext) =>
    evaluate(expression, vehicle, functionsTable);
