import { VehicleListItem } from '@cooltra/api';
import { CardButton } from '@cooltra/ui';

import {
  BatteryIcon,
  BatteryIconLabel,
  ModelImage,
  OpenTasksCount,
  OperationalStateStatusPoint,
} from '~/common';
import { useLocationName } from '~/hooks';

import { Release } from './Release/Release';

export type VehicleCardProps = {
  vehicle: VehicleListItem;
  onClick: () => void;
};

export const VehicleCard = ({
  vehicle: {
    vehicleId,
    model,
    externalId,
    locationId,
    operationalState,
    identificationNumber,
    status,
    openTasks,
  },
  onClick,
}: VehicleCardProps) => {
  const locationName = useLocationName(locationId);

  return (
    <CardButton
      data-testid={vehicleId}
      isMultiAction
      className="flex"
      onClick={onClick}
    >
      <div className="h-6 py-6 pl-4">
        <OperationalStateStatusPoint operationalState={operationalState} />
      </div>
      <div className="flex flex-col flex-1 items-start p-4 pl-3">
        <div className="w-full flex items-center justify-between mb-2">
          <div className="flex items-center">
            <ModelImage
              model={model}
              identificationNumber={identificationNumber}
              className="h-6 mr-2"
            />
            <span className="text-sm text-neutral-1000 font-semibold truncate">
              {externalId}
            </span>
          </div>
          <div>
            <Release vehicleId={vehicleId} />
          </div>
        </div>
        <div className="flex justify-between items-center gap-4 w-full">
          <div className="text-xs text-neutral-500 ml-1.5">
            <span>{locationName}</span>
            {locationName && !!openTasks.length && <span>{`, `}</span>}
            {!!openTasks.length && (
              <span>
                <OpenTasksCount totalTasks={openTasks.length} />
              </span>
            )}
          </div>
          {status?.batteryCharge && (
            <div className="flex items-center">
              <BatteryIconLabel
                charge={status.batteryCharge.value}
                className="mr-1.5 mt-px text-xs"
              />
              <div className="w-6 h-3">
                <BatteryIcon charge={status.batteryCharge.value} />
              </div>
            </div>
          )}
        </div>
      </div>
    </CardButton>
  );
};
