import { FormattedMessage, useIntl } from 'react-intl';
import { useReleaseVehicleMutation } from '@cooltra/api';
import { getErrorStatus } from '@cooltra/axios';
import { AxiosError } from 'axios';
import { Button } from '@cooltra/ui';

import { useNotification } from '~/libs/notifications';
import { useVehiclePolling } from '~/libs/polling-data';

import { ActionButtonProps } from '../types';

import messages from './messages';

export const Release = ({ vehicleId, onSettled }: ActionButtonProps) => {
  const { formatMessage } = useIntl();
  const { addErrorNotification, addSuccessNotification } = useNotification();
  const { activatePollingVehicle } = useVehiclePolling();

  const { isPending, mutate } = useReleaseVehicleMutation(vehicleId, {
    onSuccess: () => {
      addSuccessNotification(formatMessage(messages.successfullyReleased));
      activatePollingVehicle(vehicleId);
    },
    onError: (error: AxiosError) => {
      let errorNotification;

      switch (getErrorStatus(error)) {
        case 400:
          errorNotification = formatMessage(messages.errorCouldNotBeReleased);
          break;
        case 401:
          errorNotification = formatMessage(
            messages.notAuthorizedToPerformReleaseOnVehicle
          );
          break;
        default:
          errorNotification = formatMessage(messages.somethingWentWrong);
          break;
      }

      addErrorNotification(errorNotification);
    },
    onSettled,
  });

  const releaseVehicle = () => mutate();

  return (
    <Button
      className="rounded-full"
      loading={isPending}
      disabled={isPending}
      onClick={releaseVehicle}
    >
      <FormattedMessage {...messages.releaseVehicle} />
    </Button>
  );
};
