import { FormattedMessage, useIntl } from 'react-intl';
import { Button } from '@cooltra/ui';
import { useReleaseVehicleFromLocationMutation } from '@cooltra/api';
import { AxiosError } from 'axios';

import { useVehiclePolling } from '~/libs/polling-data';
import { useNotification } from '~/libs/notifications';

import { ActionButtonProps } from '../types';

import { getErrorMessageForTransferringVehicle } from './errors';
import messages from './messages';

export const ReleaseFromLocation = ({
  vehicleId,
  onSettled,
}: ActionButtonProps) => {
  const { activatePollingVehicle } = useVehiclePolling();
  const { formatMessage } = useIntl();

  const { addErrorNotification, addSuccessNotification } = useNotification();

  const handleOnError = (error: AxiosError) => {
    const errorMessageFromStatusCode = getErrorMessageForTransferringVehicle(
      error.response
    );

    addErrorNotification(
      errorMessageFromStatusCode.avoidTranslation
        ? errorMessageFromStatusCode.defaultMessage
        : formatMessage(errorMessageFromStatusCode)
    );
  };

  const handleOnSuccess = () => {
    addSuccessNotification(
      formatMessage(messages.successOnReleasingTheVehicleFromLocation)
    );
    activatePollingVehicle(vehicleId);
  };

  const { isPending, mutate } = useReleaseVehicleFromLocationMutation(
    vehicleId,
    {
      onError: handleOnError,
      onSuccess: handleOnSuccess,
      onSettled,
    }
  );

  const releaseVehicleFromLocation = () => mutate();

  return (
    <Button
      className="rounded-full"
      loading={isPending}
      disabled={isPending}
      onClick={releaseVehicleFromLocation}
    >
      <FormattedMessage {...messages.releaseFromLocation} />
    </Button>
  );
};
