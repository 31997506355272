import { defineMessages } from 'react-intl';

export default defineMessages({
  startWork: {
    defaultMessage: 'Start work',
  },
  workDone: {
    defaultMessage: 'Close work',
  },
  cancel: {
    defaultMessage: 'Cancel',
  },
  finishWork: {
    defaultMessage: 'Finish work',
  },
  confirm: {
    defaultMessage: 'Confirm work completion',
  },
  confirmWorkStart: {
    defaultMessage: 'Vehicle is in a rental or reservation',
  },
  confirmRentedOrReserved: {
    defaultMessage:
      'This vehicle has been reserved or rented. Are you sure you want to start working on it?',
  },
  placeholder: {
    defaultMessage: 'Add a comment (optional)',
  },
  workFinished: {
    defaultMessage: 'Work has been successfully finished',
  },
  workStarted: {
    defaultMessage: 'Work has been successfully started',
  },
  statusLabel: {
    defaultMessage: 'The vehicle will have the following status:',
  },
  notAuthorizedToStartWorkUnit: {
    defaultMessage: 'You are not authorized to start a work unit',
  },
  cannotStartWorkUnit: {
    defaultMessage:
      'Check in Electric Feel if the vehicle is in a reservation or rental',
  },
  somethingWentWrong: {
    defaultMessage: 'Something went wrong!',
  },
  weCouldNotCalculateFutureState: {
    defaultMessage:
      'We could not calculate the future state of the vehicle, but you can close the work unit either way',
  },
  refresh: {
    defaultMessage: 'Refresh',
  },
});
