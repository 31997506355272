import { FormattedMessage, useIntl } from 'react-intl';

import { BatteryIcon, SelectItemText, TopNavigation } from '~/common';
import { BatteryChargeLevel, chargePerLevel } from '~/utils/battery-levels';

import { FiltersFormMultiSelect } from '../FiltersFormMultiSelect';
import { FilterRouteProps } from '../types';
import { UnbalancedFilter } from '../UnbalancedFilter/UnbalancedFilter';

import messages from './messages';

export const BatteryFilter = ({ onClose, onBack }: FilterRouteProps) => {
  const { formatMessage } = useIntl();

  const options: { label: string; value: BatteryChargeLevel }[] = [
    {
      value: 'critical',
      label: formatMessage(messages.critical, chargePerLevel.critical),
    },
    {
      value: 'low',
      label: formatMessage(messages.low, chargePerLevel.low),
    },
    {
      value: 'normal',
      label: formatMessage(messages.normal, chargePerLevel.normal),
    },
    {
      value: 'full',
      label: formatMessage(messages.full, chargePerLevel.full),
    },
  ];

  return (
    <>
      <FiltersFormMultiSelect
        onBack={onBack}
        onClose={onClose}
        name="batteryChargeLevel"
        title={
          <TopNavigation.Title>
            <FormattedMessage {...messages.title} />
          </TopNavigation.Title>
        }
        renderOption={({ label, value }) => (
          <div className="flex items-center">
            <div className="w-6 h-3 mr-4">
              <BatteryIcon
                charge={chargePerLevel[value as BatteryChargeLevel].max}
              />
            </div>
            <SelectItemText>{label}</SelectItemText>
          </div>
        )}
        options={options}
        extraOptions={<UnbalancedFilter />}
      />
    </>
  );
};
