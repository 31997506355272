import { classNames } from '@cooltra/utils';
import { HTMLAttributes } from 'react';

export type BatteryIconLabelProps = Omit<
  HTMLAttributes<HTMLSpanElement>,
  'children'
> & {
  charge: number;
};

export const BatteryIconLabel = ({
  charge,
  className,
  ...rest
}: BatteryIconLabelProps) => {
  let textClassName;

  switch (true) {
    case charge < 15:
      textClassName = 'text-danger-400';
      break;
    case charge < 30:
      textClassName = 'text-warning-600';
      break;
    case charge < 45:
      textClassName = 'text-neutral-alternative-800';
      break;
    default:
      textClassName = 'text-success-500';
      break;
  }

  return (
    <span className={classNames(textClassName, className)} {...rest}>
      {charge}%
    </span>
  );
};
