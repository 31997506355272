import { defineMessages } from 'react-intl';
import { Telematics } from '@cooltra/api';

export const telematicsLabels = defineMessages<Telematics>({
  AXA: {
    defaultMessage: 'Axa',
  },
  ASTRA: {
    defaultMessage: 'Astra',
  },
  INVERS: {
    defaultMessage: 'Invers',
  },
  OLD: {
    defaultMessage: 'Old',
  },
  NIU: {
    defaultMessage: 'Niu',
  },
});
