export type BatteryChargeLevel = 'critical' | 'low' | 'normal' | 'full';

type BatteryMinMax = {
  min: number;
  max: number;
};

export const chargePerLevel: { [key in BatteryChargeLevel]: BatteryMinMax } = {
  critical: {
    min: 0,
    max: 14,
  },
  low: {
    min: 15,
    max: 29,
  },
  normal: {
    min: 30,
    max: 44,
  },
  full: {
    min: 45,
    max: 100,
  },
};

export const chargePerLevelNiu: { [key in BatteryChargeLevel]: BatteryMinMax } =
  {
    critical: {
      min: 0,
      max: 7,
    },
    low: {
      min: 8,
      max: 29,
    },
    normal: {
      min: 30,
      max: 44,
    },
    full: {
      min: 45,
      max: 100,
    },
  };

export const chargePerLevelBike: {
  [key in BatteryChargeLevel]: BatteryMinMax;
} = {
  critical: {
    min: 0,
    max: 19,
  },
  low: {
    min: 19,
    max: 29,
  },
  normal: {
    min: 30,
    max: 44,
  },
  full: {
    min: 45,
    max: 100,
  },
};
